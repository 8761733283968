import { useEffect } from 'react';
import Router from 'next/router';

const Home = () => {
  useEffect(() => {
    Router.push({ pathname: '/login' });
  }, []);

  return null;
};

export default Home;
